import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-8ac2e7e2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "menuBox hasScroll"
};
const _hoisted_2 = {
  class: "FoldBox"
};
import { ref, reactive, onMounted, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  __name: 'menu',

  setup(__props, {
    expose: __expose
  }) {
    const router = useRouter();
    const store = useStore();

    const _this = getCurrentInstance()?.appContext.config.globalProperties; // Vuex变量


    const state = computed(() => {
      // 使用计算属性来监听数据的变化
      return store.state;
    }); // 页面变量

    const data = reactive({
      showFold: true,
      isCollapse: false
    }); // 加载时触发

    onMounted(() => {
      // 修改当前选中菜单
      const currentPathName = router.currentRoute._rawValue.name;
      changeMenu(currentPathName); // 修改面包屑导航

      console.log(router);
      let path = router.currentRoute._rawValue.meta.info;
      let pathArr = path.split("/");
      changeBreadC(pathArr);
    });
    /* 函数区 */
    // 修改菜单选中

    const changeMenu = newVal => {
      store.commit("changeMenu", newVal); // 触发 vuex 里定义的函数
    }; // 修改面包屑参数


    const changeBreadC = newVal => {
      store.commit("changeBreadCrumb", newVal); // 触发 vuex 里定义的函数
    }; // 跳转页面


    const topath = (pathname, path) => {
      console.log(pathname);

      if (pathname === "register") {
        window.open(_this.$registerUrl);
        return;
      }

      let pathArr = path.split("/"); // 改变vuex变量

      changeBreadC(pathArr);
      changeMenu(pathname); // 跳转

      router.push({
        name: pathname
      });
    }; // 折叠菜单面板


    const toFold = () => {
      if (data.showFold) {
        data.showFold = false;
        data.isCollapse = true;
      } else {
        data.showFold = true;
        data.isCollapse = false;
      }
    }; // 来自父级的折叠与显示


    let mshow = ref(false);

    const mFold = () => {
      console.log("变了");
      mshow.value = !mshow.value;
    };

    const tostop = e => {
      e.stopPropagation();
    };

    __expose({
      mFold
    });

    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_menu_item = _resolveComponent("el-menu-item");

      const _component_el_sub_menu = _resolveComponent("el-sub-menu");

      const _component_el_menu = _resolveComponent("el-menu");

      const _component_fold = _resolveComponent("fold");

      const _component_expand = _resolveComponent("expand");

      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["menu-media", _unref(mshow) ? 'mune-show' : 'mune-hide']),
        onClick: _withModifiers(mFold, ["stop"])
      }, [_createElementVNode("div", {
        class: _normalizeClass(["contentBox", !data.showFold ? 'contentBoxHide' : '']),
        onClick: tostop
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_menu, {
        "collapse-transition": true,
        "default-active": state.value.menuActive,
        class: "el-menu-vertical-demo",
        collapse: data.isCollapse,
        "active-text-color": "#82C9C3"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.value.pageInfos, (item, index) => {
          return _openBlock(), _createElementBlock(_Fragment, {
            key: index
          }, [item.role.indexOf(state.value.adminUserInfo.role) != -1 ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [item.group !== '' ? (_openBlock(), _createBlock(_component_el_sub_menu, {
            key: 0,
            index: item.group
          }, {
            title: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(item.group)))]),
              _: 2
            }, 1024), _createElementVNode("span", null, _toDisplayString(item.name), 1)]),
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (ite, idx) => {
              return _openBlock(), _createElementBlock(_Fragment, {
                key: idx
              }, [ite.role.indexOf(state.value.adminUserInfo.role) != -1 ? (_openBlock(), _createBlock(_component_el_menu_item, {
                key: 0,
                index: ite.path,
                onClick: $event => topath(ite.path, ite.info)
              }, {
                default: _withCtx(() => [_createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(ite.icon)))]),
                  _: 2
                }, 1024), _createElementVNode("span", null, _toDisplayString(ite.name), 1)]),
                _: 2
              }, 1032, ["index", "onClick"])) : _createCommentVNode("", true)], 64);
            }), 128))]),
            _: 2
          }, 1032, ["index"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
            key: 1,
            index: item.path,
            onClick: $event => topath(item.path, item.info)
          }, {
            default: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))]),
              _: 2
            }, 1024), _createElementVNode("span", null, _toDisplayString(item.name), 1)]),
            _: 2
          }, 1032, ["index", "onClick"]))], 64)) : _createCommentVNode("", true)], 64);
        }), 128))]),
        _: 1
      }, 8, ["default-active", "collapse"])]), _createElementVNode("div", _hoisted_2, [data.showFold ? (_openBlock(), _createBlock(_component_el_icon, {
        key: 0,
        onClick: toFold
      }, {
        default: _withCtx(() => [_createVNode(_component_fold)]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_el_icon, {
        key: 1,
        onClick: toFold
      }, {
        default: _withCtx(() => [_createVNode(_component_expand)]),
        _: 1
      }))])], 2)], 2);
    };
  }

};