import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
// sotre值
const shopId = store.getters.getShopId
export default {
    // 文章列表
    async articleList(data) {
        data = {
            ...data,
            shop_id: shopId
        }
        // /" , shopId
        return await toPost(adminurl + "article/show", data)
    },

}