import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
const { toGet, toPost } = tools;
export default {
    // 发送短信验证码
    async getSmsCode(mobile) {
        return await toGet(weburl + "get_sms_code/", mobile)
    },

    // 平台登录
    async adminLogin(data) {
        return await toPost(adminurl + "index/login", data)
    },



    // 今日订单数据
    async showTodayOrdersInfo() {
        return await toGet(adminurl + "index/show_today_orders_info/", 0)
    },
    // 今日订单数据
    async showTodayServeOrdersInfo() {
        return await toGet(adminurl + "index/show_today_server_orders_info/", 0)
    },
    // 今日新增用户
    async showTodayNewPeople() {
        return await toGet(adminurl + "index/show_today_new_people/", 0)
    },
    // 今日采购单数据
    async showTodayRoleOrdersInfo() {
        return await toGet(adminurl + "index/show_today_role_orders_info/", 0)
    },
    // 今日提现
    async showTodayBalanceTx() {
        return await toGet(adminurl + "index/show_today_balance_tx", 0)
    },
    // 柱状图统计
    async goodsSaleCurve(data) {
        data = {
            ...data,
            shop_id: 0
        }
        return await toPost(adminurl + "index/goods_sale_curve", data)
    },
    // 曲线图统计
    async salesCurve(data) {
        data = {
            ...data,
            shop_id: 0
        }
        return await toPost(adminurl + "index/sales_curve", data)
    },
    // 饼图统计
    async goodsCateCurve(data) {
        data = {
            ...data,
            shop_id: 0
        }
        return await toPost(adminurl + "index/goods_cate_curve", data)
    },

}