import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
// sotre值
const shopId = store.getters.getShopId
export default {
    // 订单列表
    async orderList(data) {
        data = {
            ...data,
            shop_id: shopId
        }
        return await toPost(adminurl + "order/show", data)
    },
    // 订单列表
    async orderGoodsInfo(id) {
        return await toGet(adminurl + "order/order_goods_info/", id)
    },
    // 订单发货
    async orderTrack(data) {
        return await toGet(adminurl + "order/track_number", data)
    },



















    // 物流公司列表
    async trackCode() {
        return await toGet(adminurl + "order/track_code")
    },

}